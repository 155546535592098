import React from 'react';
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function ThouzanSeparator(props) {
    return (
        <span>
            {numberWithCommas(props.value)}
        </span>
    );
}

export default ThouzanSeparator;