import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useSearchParams } from "react-router-dom";
import ThouzanSeparator from "../../utils/ThouzanSeparator";
import MerchandiseLoader from "../../utils/placeholder/MerchandiseLoader";
import Select from "react-select";
import Loading from "../../utils/Loading";

export default function Checkout(props) {
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const formData = new FormData();
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };

  const [merchandise, setMerchandise] = useState();
  const [loading, setLoading] = useState(true);
  const [fetch, setFetch] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [campaignId, setCampaignId] = useState();
  const [productId, setProductId] = useState();
  const [spvId, setSpvId] = useState();
  const [spv, setSpv] = useState();
  const [products, setProducts] = useState();
  const [campaigns, setCampaigns] = useState();

  const customer = JSON.parse(localStorage.getItem("customer"));
  const crm = JSON.parse(localStorage.getItem("crmData"));
  const fetchData = (props) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/checkout?merch_id=${props}&customer_id=${customer.id}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setMerchandise(response.data.data.merchandise);
        setProducts(
          response.data.data.products.map((p) => ({
            value: p.id,
            label: p.display_name,
          }))
        );
        setCampaigns(
          response.data.data.campaigns.map((c) => ({
            value: c.id,
            label: c.display_name,
          }))
        );
        setSpv(
          response.data.data.spv.map((s) => ({
            value: s.id,
            label: s.display_name,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          Swal.fire("Error", `${error.response.data.message}`, "error").then((e) => {
            if(e.isConfirmed){
              navigate(-1);
            }
          });
        } else {
          Swal.fire("Error", `${error.message}`, "error").then((e) => {
            if(e.isConfirmed){
              navigate(-1);
            }
          });
        }
      });
  };

  const submitOrder = async () => {
    setFetch(true);
    formData.append("customer_id", customer.id);
    formData.append("crm_staff_id", crm.id);
    formData.append("campaign_id", campaignId);
    formData.append("product_id", productId);
    formData.append("merchandise_id", merchandise.id);
    formData.append("spv_id", spvId);

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/checkout`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        Swal.fire("Success", "Berhasil membuat order", "success").then((e) => {
          if(e.isConfirmed){
            window.location.replace("/dashboard");
          }        
        });
      })
      .catch((error) => {
        setFetch(false);
        Swal.fire("Error", `${error.response.data.message}`, "error");
      });
  };

  const checkboxHandler = (event) => {
    setIsChecked((current) => !current);
  };

  useEffect(() => {
    fetchData(queryParameters.get("id"));
  }, []);
  return (
      <div className="flex flex-col mb-20">
        <div className="flex justify-center items-center flex-grow">
          <Loading isLoading={fetch} />
          <div className="flex justify-center items-center">
            <div>
              <div className="flex justify-center border-y-2 border-purple-600 py-3 my-5">
                <label
                  htmlFor="search"
                  className="text-center text-2xl font-black text-purple-600"
                >
                  Order Summary
                </label>
              </div>
              <div className="mx-5 border-y-2 border-purple-600 py-5 flex justify-center items-center">
                <span className="text-lg text-purple-600 text-center">
                  Product Detail
                </span>
              </div>
              {loading ? (
                <div className="flex justify-center">
                  <MerchandiseLoader />
                </div>
              ) : (
                <div className="my-5 mx-5">
                  <div className="flex justify-center">
                    <div className="bg-purple-600 rounded-md focus:drop-shadow-md p-2">
                      <div>
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}${merchandise.image}`}
                          alt={`${merchandise.name}`}
                          className="rounded-md h-[250px] w-auto"
                        />
                      </div>
                      <div className="my-2 mx-1 text-white">
                        <p>{merchandise.display_name}</p>
                        <div className="grid grid-cols-5">
                          <div className="col col-span-3">
                            <p className="font-black">
                              <ThouzanSeparator
                                value={merchandise.price_by_point}
                              />{" "}
                              Point
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mx-5 border-y-2 border-purple-600 py-5 flex justify-center items-center">
                <span className="text-lg text-purple-600 text-center">
                  Customer Detail
                </span>
              </div>
              <div className="mt-5 mx-5">
                <div>
                  <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                      Name
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                      {customer.name}
                    </p>
                  </div>
                  <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Total Point
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {customer.customer_point.point}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Phone
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {customer.phone}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Kota/Kabupaten
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {customer.city}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Provinsi
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {customer.state}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Alamat
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {customer.address}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
              <div>
                <div className="border-t-4 border-purple-600 my-5 py-5 px-10">
                  <div>
                    <span className="text-center text-lg">Nama Produk</span>
                  </div>
                  {!loading ? <Select options={products} onChange={(e) => {
                    setProductId(e.value);
                  }}/> : ""}
                </div>
              </div>
              <div>
                <div className="border-t-4 border-purple-600 my-5 py-5 px-10">
                  <div>
                    <span className="text-center text-lg">Campaign Name</span>
                  </div>
                  {!loading ? <Select options={campaigns} onChange={(e) => {
                    setCampaignId(e.value);
                  }}/> : ""}
                </div>
              </div>
              <div>
                <div className="border-t-4 border-purple-600 my-5 py-5 px-10">
                  <div>
                    <span className="text-center text-lg">SPV Name</span>
                  </div>
                  {!loading ? <Select options={spv} onChange={(e) => {
                    setSpvId(e.value);
                  }}/> : ""}
                </div>
              </div>
              <div>
                <div className=" my-5 py-5 px-10 flex gap-5">
                  <input
                    id="validCheckbox"
                    type="checkbox"
                    value={isChecked}
                    onChange={checkboxHandler}
                    className="rounded-md w-5"
                  />
                  <label htmlFor="validCheckbox">
                    Data di atas sudah benar
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-2 py-10 mb-10">
                <div className="flex justify-center">
                  <button
                    onClick={() => navigate(-1)}
                    className="flex items-center text-lg bg-gray-400 focus:bg-purple-500 focus:drop-shadow-md rounded-md text-white px-3 py-1"
                  >
                    Back
                  </button>
                </div>
                <div className="flex justify-center">
                  <button
                    id="submitBtn"
                    disabled={isChecked === true ? false : true}
                    onClick={submitOrder}
                    className="flex items-center disabled:bg-gray-400 disabled:ring-2 disabled:ring-purple-600 text-lg bg-purple-600 focus:bg-purple-500 focus:drop-shadow-md rounded-md text-white px-3 py-1"
                  >
                    Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
