import React, { useEffect, useState } from "react";
import ListSquareThumbnail from "../../utils/placeholder/ListSquareThumbnail";
import SingleSquareRounded from "../../utils/placeholder/SingleSquareRounded";
import Swal from "sweetalert2";
import axios from "axios";
import Order from "../../molecules/Order";

export default function Crm() {
  const [loading, setLoading] = useState(true);
  const [crmTotalOrder, setTotalOrder] = useState();
  const [crmTotalRedeemPoint, setTotalPoint] = useState();
  const [orders, setOrders] = useState();

  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };

  const fetchData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/dashboard/crm`, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        setTotalOrder(response.data.data.crm.total_merch_selling);
        setTotalPoint(response.data.data.crm.total_point_redeemed);
        setOrders(
          response.data.data.order.map((dataObj, index) => {
            return (
              <>
                <Order data={dataObj} />
              </>
            );
          })
        );
      })
      .catch((error) => {
        setLoading(false);
        if (error.response !== undefined) {
          Swal.fire("Error", `${error.response.data.message}`, "error");
        } else {
          Swal.fire("Error", `${error.message}`, "error");
        }
        setTotalOrder("N/A");
        setTotalPoint("N/A");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="mt-5">
        <div className="flex justify-center">
          <img
            className="inline-block object-cover h-32 w-32 rounded-full ring-2 ring-white"
            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            alt=""
          ></img>
        </div>
        <div className="flex justify-center my-5">
          <span className="text-2xl font-bold text-purple-600">{localStorage.userName}</span>
        </div>
      </div>
      <div className="rounded-md bg-purple-600 drop-shadow-md grid grid-cols-2 py-5 mx-2">
        <div className="border-r-[5px] border-r-white grid-rows-2 justify-center">
          <div className="flex justify-center">
            <span className="text-lg text-white text-center">
              Total Penukaran
            </span>
          </div>
          <div className="flex justify-center">
            {!loading ? (
              <span className="text-lg text-white">
                <span className="text-3xl">{crmTotalOrder}</span> Alamat
              </span>
            ) : (
              <SingleSquareRounded />
            )}
          </div>
        </div>
        <div className="grid-rows-2 justify-center">
          <div className="flex justify-center">
            <span className="text-lg text-white text-center">Total Point</span>
          </div>
          <div className="flex justify-center">
            {!loading ? (
              <span className="text-sm text-white">
                <span className="text-3xl">{crmTotalRedeemPoint}</span> pts
              </span>
            ) : (
              <SingleSquareRounded />
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 border-y-4 border-y-purple-600 py-1 flex justify-center">
        <span className="text-2xl">Recent Order</span>
      </div>
      {loading ? (
          <>
            <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
              <ListSquareThumbnail />
            </div>
            <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
              <ListSquareThumbnail />
            </div>
            <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
              <ListSquareThumbnail />
            </div>
            <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
              <ListSquareThumbnail />
            </div>
            <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
              <ListSquareThumbnail />
            </div>
          </>
        ) : (
          <div className="overflow-y-scroll h-[calc(100vh-500px) mb-[80px]">{orders}</div>
        )}
    </>
  );
}
