import React from "react";
import ContentLoader from "react-content-loader";

const MerchandiseLoader = (props) => (
  <div className="bg-purple-600 rounded-md focus:drop-shadow-md p-2">
    <ContentLoader
      speed={2}
      width={220}
      height={200}
      viewBox="0 0 220 200"
      backgroundColor="#d4d4d4"
      foregroundColor="#e4ecf3"
      {...props}
    >
      <rect x="1" y="5" rx="9" ry="9" width="200" height="135" />
      <rect x="4" y="155" rx="6" ry="6" width="157" height="13" />
      <rect x="5" y="180" rx="7" ry="7" width="59" height="13" />
    </ContentLoader>
  </div>
);

export default MerchandiseLoader;
