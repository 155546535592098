import React, { useState } from "react";
import { Text, Box, Input, Center, Button, Icon } from "native-base";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "../../utils/Loading";
import { MdSearch } from "react-icons/md";

export default function Order() {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const findCustomer = async () => {
    setLoading(true);
    //initialize formData
    const formData = new FormData();
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };
    //append data to formData
    formData.append("phone", phone);

    //send data to server
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/customer/find`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        localStorage.setItem("customer", JSON.stringify(response.data.data));
        window.location.replace("/order/customer-detail");
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire("Error", `${error.response.data.message}`, "error");
      });
    setLoading(false);
  };

  return (
    <>
      <Loading isLoading={loading} />
      <div class="flex flex-col h-[calc(100vh-200px)]">
        <div class="flex justify-center items-center flex-grow">
          <div className="flex justify-center items-center">
            <div>
              <div className="flex justify-center">
                <label htmlFor="search" className="text-center text-xl">
                  Cari Customer
                </label>
              </div>
              <div className="mt-5">
                <input
                  id="search"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  placeholder="628123xxxx"
                  className="text-center rounded-md h-10 ring-1 focus:ring-4 ring-gray-200 focus:ring-purple-600 focus:drop-shadow-md text-xl"
                />
              </div>
              <div className="flex justify-center mt-5">
                <button onClick={() => findCustomer()}
                className="flex items-center text-lg bg-purple-600 focus:bg-purple-500 focus:drop-shadow-md rounded-md text-white px-3 py-1">
                  Cari <MdSearch className="mx-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
