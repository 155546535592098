import React from "react";
import AtomicSpinner from "atomic-spinner";
function Loading(e) {
  return (
    <div>
      {e.isLoading ? (
        <div className="flex w-full h-screen absolute justify-center items-center z-20 backdrop-blur-sm animate-fade">
          <div>
            <div className="flex justify-center">
              <AtomicSpinner />
            </div>
            <div className="flex justify-center">
              <span className="text-2xl text-slate-600">Please Wait</span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Loading;
