import React from "react"
import ContentLoader from "react-content-loader"

const ListSquareThumbnail = (props) => (
  <ContentLoader 
    speed={2}
    width={400}
    height={100}
    viewBox="0 0 400 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="150" y="25" rx="3" ry="3" width="215" height="9" /> 
    <rect x="150" y="50" rx="3" ry="3" width="234" height="9" /> 
    <rect x="150" y="75" rx="3" ry="3" width="178" height="9" />  
    <rect x="20" y="16" rx="0" ry="0" width="100" height="68" />
  </ContentLoader>
)

export default ListSquareThumbnail

