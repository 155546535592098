import React from "react";
import { MdHome, MdShoppingBasket, MdListAlt, MdLogout } from "react-icons/md";
import { Outlet, NavLink } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function Authenticated() {
  const logoutHandler = async () => {
    //send data to server
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/logout`, "", {
        headers: headers,
      })
      .then((response) => {
        //set token on localStorage
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("isAuthenticated");
        window.location.replace("/");
      })
      .catch((error) => {
        Swal.fire("Error", `${error.response.data.message}`, "error");
      });
  };

  return (
    <>
      <div className="py-3 border-b-[1px] border-gray-200 flex justify-end px-3 drop-shadow-md">
        <button onClick={() => logoutHandler()} backgroundColor={"white"}>
          <MdLogout className="mx-1 text-xl" />
        </button>
      </div>
      <Outlet />
      <div className="bg-purple-600 w-full  h-16 px-6 py-2 flex justify-between text-gray-font fixed bottom-0 shadow-lg z-40">
        <NavLink
          to="/dashboard"
          className={({ isActive, isPending }) =>
            isActive ? "text-white" : "text-purple-400"
          }
        >
          <MdHome className="mx-1 text-4xl" />
        </NavLink>
        `
        <NavLink
          to="/order"
          className={({ isActive, isPending }) =>
            isActive ? "text-white" : "text-purple-400"
          }
        >
          <MdShoppingBasket className="mx-1 text-4xl" />
        </NavLink>
        `
        <NavLink
          to="/history"
          className={(navData) =>
            navData.isActive ? "text-white" : "text-purple-500"
          }
        >
          <MdListAlt className="mx-1 text-4xl" />
        </NavLink>
      </div>
    </>
  );
}
