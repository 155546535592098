import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";

export default function CustomerDetail(props) {
  const navigate = useNavigate();
  const customer = JSON.parse(localStorage.getItem("customer"));
  return (
      <div className="flex justify-center items-center flex-grow mb-20">
        <div className="flex justify-center items-center">
          <div>
            <div className="flex justify-center border-y-2 border-purple-600 py-3 my-5">
              <label
                htmlFor="search"
                className="text-center text-2xl font-black text-purple-600"
              >
                Customer Detail
              </label>
            </div>
            <div className="mt-5">
              <div>
                <div className="px-4 sm:px-0">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    Name
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                    {customer.name}
                  </p>
                </div>
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Total Point
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {customer.customer_point.point}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Phone
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {customer.phone}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Kota/Kabupaten
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {customer.city}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Provinsi
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {customer.state}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Alamat
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {customer.address}
                      </dd>
                    </div>
                    <div className="grid grid-cols-2 py-10">
                      <div className="flex justify-center">
                        <button
                          onClick={() => navigate(-1)}
                          className="flex items-center text-lg bg-gray-400 focus:bg-purple-500 focus:drop-shadow-md rounded-md text-white px-3 py-1"
                        >
                          Back
                        </button>
                      </div>
                      <div className="flex justify-center">
                        <Link
                          to={"/order/select-merchandise"}
                          className="flex items-center text-lg bg-purple-600 focus:bg-purple-500 focus:drop-shadow-md rounded-md text-white px-3 py-1"
                        >
                          Next
                        </Link>
                      </div>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
