import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import MerchandiseLoader from "../../utils/placeholder/MerchandiseLoader";
import { Link } from "react-router-dom";
import ThouzanSeparator from "../../utils/ThouzanSeparator";
import { MdSearch } from "react-icons/md";

export default function SelectMerch() {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(null);
  const [merchandises, setMerchandises] = useState();

  const fetchMerchandise = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/merchandise`, {
        headers: headers,
      })
      .then((response) => {
        setMerchandises(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response !== undefined) {
          Swal.fire("Error", `${error.response.data.message}`, "error");
        } else {
          Swal.fire("Error", `${error.message}`, "error");
        }
      });
  };
  useEffect(() => {
    fetchMerchandise();
  }, []);

  return (
    <div class="flex flex-col mb-20">
      <div>
        <h1 className="my-5 py-5 text-center border-y-2 border-purple-600 text-xl font-black text-purple-600">
          List Merchandise
        </h1>
      </div>
      <div className="border-2 rounded-md border-purple-600 p-5 mb-10 mx-2 flex justify-center gap-2">
        <input
          id="search"
          value={searchTerm}
          placeholder="Cari merchandise"
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border-b-[1px] border-purple-600 text-center"
          type="text"
        />
      </div>
      <div className="grid grid-cols-2 gap-5 mx-2">
        {loading ? (
          <>
            <MerchandiseLoader />
            <MerchandiseLoader />
            <MerchandiseLoader />
            <MerchandiseLoader />
            <MerchandiseLoader />
            <MerchandiseLoader />
          </>
        ) : (
          <>
            {merchandises
              .filter((data) => {
                if (searchTerm == null) return data;
                else if (
                  data.display_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return data;
                }
              })
              .map((dataObj, index) => {
                return (
                  <div className="bg-purple-600 rounded-md focus:drop-shadow-md p-2">
                    <div>
                      <img
                        src={`${process.env.REACT_APP_BACKEND_URL}${dataObj.image}`}
                        alt={`${dataObj.name}`}
                        className="rounded-md"
                      />
                    </div>
                    <div className="my-2 mx-1 text-white">
                      <p>{dataObj.display_name}</p>
                      <div className="grid grid-cols-5">
                        <div className="col col-span-3">
                          <p className="font-black">
                            <ThouzanSeparator value={dataObj.price_by_point} />{" "}
                            Point
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Link
                        to={`/order/checkout?id=${dataObj.id}`}
                        className="text-center flex items-center justify-center h-8 bg-white text-purple-600 focus:bg-purple-500 focus:text-white-300 w-full rounded-b-md"
                      >
                        Select
                      </Link>
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
}
