import React from "react"
import ContentLoader from "react-content-loader"

const SingleSquareRounded = (props) => (
  <ContentLoader 
    speed={2}
    width={100}
    height={25}
    viewBox="0 0 150 35"
    backgroundColor="#d4d4d4"
    foregroundColor="#e4ecf3"
    {...props}
  >
    <rect x="13" y="1" rx="3" ry="3" width="133" height="33" />
  </ContentLoader>
)

export default SingleSquareRounded

