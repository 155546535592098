import React from "react";

function Order(props) {
  let imageUrl = `${process.env.REACT_APP_BACKEND_URL}${props.data.merchandises[0].image}`;
  let status =
    '<span className="rounded-r-md bg-orange-400 px-3 py-1">status</span>';

  return (
    <div className="bg-purple-600 rounded-md mx-2 h-36 my-1 grid grid-cols-12 gap-3">
      <div className="col col-span-4 p-2">
        <img
          src={imageUrl}
          alt={props.data.merchandises[0].name}
          className="h-32 w-[250px] object-cover rounded-md"
        />
      </div>
      <div className="text-white rounded-md col col-span-8">
        <div className="mt-3">
          <p>{props.data.customer_name}</p>
          <p>Item : {props.data.merchandises[0].display_name}</p>
          <p>Total: {props.data.merchandises[0].price_by_point} <span>pts</span></p>
          <p>Tanggal: {props.data.created_at}</p>
          <div className="flex justify-end mt-2 mx-1">
            {props.data.status === 2
              ? <span className="rounded-br-md bg-blue-400 px-3">Waiting Finance</span>
              : props.data.status === 3
              ? <span className="rounded-br-md bg-green-400 px-3">Success Order</span>
              : <span className="rounded-br-md bg-orange-400 px-3">Waiting Fulfillment</span>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
