import { useState, useEffect } from "react";
import ListSquareThumbnail from "../utils/placeholder/ListSquareThumbnail";
import Order from "../molecules/Order";
import axios from "axios";
import Swal from "sweetalert2";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { BiFilterAlt } from "react-icons/bi";
import Loading from "../utils/Loading";

export default function History() {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [list, setList] = useState([]);
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  };
  const fetchData = (q) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/history${
          params !== null ? params : ""
        }`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setList(
          response.data.data.map((dataObj, index) => {
            return (
              <>
                <Order data={dataObj} />
              </>
            );
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response !== undefined) {
          Swal.fire("Error", `${error.response.data.message}`, "error");
        } else {
          Swal.fire("Error", `${error.message}`, "error");
        }
      });
  };

  const settingList = (props) => {
    setList(
      props.map((dataObj, index) => {
        return (
          <>
            <Order data={dataObj} />
          </>
        );
      })
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleFilter = async () => {
    setLoading(true);
    setParams(`?startDate=${startDate}&endDate=${endDate}`);
    let res = await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/history${
          params !== null ? params : ""
        }`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        settingList(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response !== undefined) {
          Swal.fire("Error", `${error.response.data.message}`, "error");
        } else {
          Swal.fire("Error", `${error.message}`, "error");
        }
      });
  };

  useEffect(() => {
    fetchData(params);
  }, []);
  return (
    <div class="flex flex-col mb-20 h-[100vh]">
      <Loading isLoading={loading} />
      <div className="flex justify-center border-y-2 border-purple-600 py-3 mt-5">
        <span className="text-center text-2xl font-black text-purple-600">
          History
        </span>
      </div>
      <div className="border-2 rounded-md mx-2 my-3 border-purple-500">
        <div className="my-5 grid grid-cols-2">
          <div className="flex justify-center items-center">
            <div className="w-full px-5">
              <label htmlFor="startDate">Start Date</label>
              <div className="flex w-full">
                <input
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  id="startDate"
                  type="date"
                  className="rounded-md w-full ring-2 ring-gray-300 focus:ring-purple-500"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="w-full px-5">
              <label htmlFor="endDate">End Date</label>
              <div className="flex w-full">
                <input
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  id="endDate"
                  type="date"
                  className="rounded-md w-full ring-2 ring-gray-300 focus:ring-purple-500"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center py-2">
          <button
            onClick={handleFilter}
            className="rounded-md text-white px-3 py-2 focus:ring-purple-800 bg-purple-500 focus:bg-purple-400"
          >
            <span className="mx-5 flex justify-center items-center">
              <BiFilterAlt className="mr-2" />
              Filter
            </span>
          </button>
        </div>
      </div>
      {loading ? (
        <>
          <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
            <ListSquareThumbnail />
          </div>
          <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
            <ListSquareThumbnail />
          </div>
          <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
            <ListSquareThumbnail />
          </div>
          <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
            <ListSquareThumbnail />
          </div>
          <div className="bg-purple-600 rounded-md mx-2 my-2 h-[100px]">
            <ListSquareThumbnail />
          </div>
        </>
      ) : (
        <div className="overflow-y-scroll h-[calc(100vh-500px)">{list}</div>
      )}
    </div>
  );
}
