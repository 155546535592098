import Login from "./components/pages/auth/Login";
import "./App.css";
import Crm from "./components/pages/dashboards/Crm";
import { Routes, Route } from "react-router-dom";
import Authenticated from "./components/templates/Authenticated";
import Order from "./components/pages/orders/index";
import History from "./components/pages/History";
import SelectMerch from "./components/pages/orders/SelectMerch";
import CustomerDetail from "./components/pages/orders/CustomerDetail";
import Checkout from "./components/pages/orders/Checkout";

function App() {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Authenticated />}>
          <Route index element={<Crm />} />
        </Route>
        <Route path="history" element={<Authenticated />}>
          <Route index element={<History />} />
        </Route>
        <Route path="order" element={<Authenticated />}>
          <Route index element={<Order />} />
          <Route path="customer-detail" element={<CustomerDetail />} />
          <Route path="select-merchandise" element={<SelectMerch />} />
          <Route path="checkout" element={<Checkout />} />
        </Route>
        <Route
          path="*"
          element={
            <div className="h-screen flex justify-center items-center">
              <h1 className="font-black text-4xl text-purple-600">
                404 Not Found!
              </h1>
            </div>
          }
        />
      </Routes>
    </main>
  );
}

export default App;
