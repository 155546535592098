import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "../../utils/Loading";
import { Link } from "react-router-dom";

export default function App() {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    //initialize formData
    const formData = new FormData();

    //append data to formData
    formData.append("phone", phone);
    formData.append("password", password);

    //send data to server
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, formData)
      .then((response) => {
        //set token on localStorage
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem(
          "userData",
          JSON.stringify(response.data.data.user)
        );
        localStorage.setItem("userName", response.data.data.user.name);
        localStorage.setItem(
          "crmData",
          JSON.stringify(response.data.data.user.crm)
        );
        localStorage.setItem("isAuthenticated", "true");
        window.location.replace("/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response !== undefined) {
          Swal.fire("Error", `${error.response.data.message}`, "error");
        } else {
          Swal.fire("Error", `${error.message}`, "error");
        }
      });
    setLoading(false);
  };

  return (
    <>
    <Loading isLoading={loading}/>
      <div className="pt-2 h-[20vh] bg-purple-600 z-0 flex justify-center">
        <div className="mt-10">
          <h1 className="text-center text-white font-semibold text-2xl">
            Welcome
          </h1>
          <h2 className="text-center text-white text-md">
            Sign in to continue
          </h2>
        </div>
      </div>
      <div className="rounded-t-2xl bg-white z-10 -mt-5 flex justify-center items-center h-[80vh]">
        <div>
          <form onSubmit={loginHandler}>
            <div className="my-2">
              <label
                htmlFor="phone"
                className="text-center flex justify-center text-purple-700 text-xl"
              >
                Phone Number
              </label>
              <div>
                <input
                  id="phone"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="py-1 text-center rounded-md ring-2 ring-gray-200 focus:ring-purple-500 text-xl"
                  placeholder="6282123xxxxx"
                />
              </div>
            </div>
            <div className="my-2">
              <label
                htmlFor="password"
                className="text-center flex justify-center text-purple-700 text-xl"
              >
                Password
              </label>
              <div>
                <input
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="py-1 text-center rounded-md ring-2 ring-gray-200 focus:ring-purple-500 text-xl"
                />
              </div>
            </div>
            <div className="flex justify-center mt-5">
              <button
                className="rounded-md px-3 py-2 text-white bg-purple-600"
                onClick={loginHandler}
              >
                <span>Sign In</span>
              </button>
            </div>
          </form>
          <div className="flex justify-center mt-5">
              <span>Not have account yet?</span> <Link to={process.env.REACT_APP_BACKEND_URL + '/auth/register/token'}
              className="px-2 text-purple-600 hover:font-black font-semibold">Sign Up</Link>
          </div>
        </div>
      </div>
    </>
  );
}
